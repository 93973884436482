import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { AnalyticsLocationProvider } from 'latitude-analytics';
import Section from '@latitude/section';
import { Heading4, Heading5, Heading6 } from '@latitude/heading';
import { Accordion } from '@latitude/accordion';
import { Text } from '@latitude/text';
import { Link } from '@latitude/link';
import { FeaturesSlider } from '@latitude/features-slider';
import { Promo } from '@latitude/promo';
import { Metadata } from '@latitude/metadata';
import { StickyCta } from '@latitude/sticky-cta';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { Tel } from '@latitude/tel';
import { List, ListItem } from '@latitude/list';
import { HorizontalRule } from '@latitude/horizontal-rule';
import {
  ALIGN,
  BREAKPOINT,
  BUTTON_STYLE,
  COLOR,
  PADDING
} from '@latitude/core/utils/constants';

import Lframe from '@/components/Lframe/Lframe';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import { Box } from '@/components/Box/Box';
import Layout from '@/components/layout';
import ManageYourLoan from '@/components/ManageYourLoan/ManageYourLoan';

import PageData from './hardship.json';

const hardshipApplicationFormUrl =
  'https://gemfinance.zendesk.com/hc/en-nz/requests/new?ticket_form_id=360000127417';
const hardshipPhoneNumber = '0800 433 033';

const Hardship = props => {
  return (
    <Layout location={props.location}>
      <main
        className="navigation-spacer"
        css={`
          background-color: ${COLOR.GREY6};
        `}
      >
        <Metadata
          title={PageData.metaTitle}
          description={PageData.metaDesc}
          canonical={PageData.path}
        />

        <Lframe financialHardship />
        <HeroBranded
          css={`
            @media (min-width: ${BREAKPOINT.XL}) {
              .HeroContent {
                padding-bottom: 80px;
              }
            }
            a {
              max-width: none;
            }
          `}
          title={PageData.content.hero.title}
          text={
            <>
              When the unexpected happens and you're having difficulty managing
              your Gem repayments,{' '}
              <strong>Hardship Care is here to help.</strong>
            </>
          }
        />

        <div
          className="d-none d-lg-block"
          css="position: relative; z-index: 11;
          .sticky-navigation--fixed {
            position: fixed;
            }"
        >
          <StickyNavigation
            items={PageData.nav}
            offsetElem="[data-sticky-navigation-offset]"
            ctaText="Apply for Hardship Care"
            ctaHref={hardshipApplicationFormUrl}
            ctaType={BUTTON_STYLE.SECONDARY}
            trackId="sticky-navigation-hardship-application"
            trackEventData={{
              location: 'Sticky Navigation',
              action: 'internal-link'
            }}
          />
        </div>
        <div
          className="d-lg-none"
          css={`
            .sticky-cta__button {
              background-color: ${COLOR.BLUE} !important;
              border: 0 !important;
              &:hover {
                background-color: ${COLOR.BLUE_DARK} !important;
              }
            }
          `}
        >
          <StickyCta
            href={hardshipApplicationFormUrl}
            trackId="sticky-cta--hardship-application"
            text="Apply for Hardship Care"
            targetBlank
          />
        </div>

        <IntroSection />
        <HorizontalRule />
        <ApplyingForHardshipCareSection />
        <HorizontalRule />
        <InsuranceSection />
        <HorizontalRule />
        <UsefulInformationSection />
        <ContactSection />
      </main>
    </Layout>
  );
};

const IntroSection = () => (
  <Section
    id="what-is-hardship"
    align={ALIGN.LEFT}
    css={`
      padding: 72px 0 !important;
      background-color: ${COLOR.GREY6};
    `}
  >
    <Box
      backgroundColor={COLOR.GREY6}
      css={`
        max-width: 730px;
        margin: 0 auto;
        padding: 0 32px;
        text-align: left;
      `}
    >
      <Heading4
        align={ALIGN.CENTER}
        color={COLOR.BLACK}
        css={`
          margin: 0 0 24px;
        `}
      >
        What is financial hardship?
      </Heading4>
      <Text>
        Financial hardship is when you want to make your repayments on a Gem
        credit card, personal loan or car loan, but can't.
        <br />
        <br />
        <strong>
          Financial hardship can be caused by many unexpected factors. Some of
          these may include:
        </strong>
      </Text>
      <List css="margin: 20px;">
        <ListItem>Injury or illness</ListItem>
        <ListItem>Unemployment or a reduction in income</ListItem>
        <ListItem>Relationship breakdown</ListItem>
        <ListItem>Domestic violence</ListItem>
        <ListItem>Financial abuse</ListItem>
        <ListItem>Gambling</ListItem>
      </List>
      <Text>
        <strong>
          If you're experiencing financial hardship, our Hardship Care team will
          help you get back on track.
        </strong>
      </Text>
    </Box>
  </Section>
);

const ContactSection = () => (
  <AnalyticsLocationProvider location="What's the best way to contact Hardship Care?">
    <Box.Section
      id="contact"
      backgroundColor={COLOR.BLUE_BABY}
      css={`
        h5 {
          font-size: 18px;
          margin-bottom: 8px;
          line-height: 18px;
          height: auto;
        }
      `}
    >
      <FeaturesSlider
        data={[
          {
            icon: 'icon-application',
            title: 'Online',
            text:
              "You should allow about 20 minutes to complete your Hardship Care application. It's a good idea to have all the relevant information you'll need ready, before you start.",
            jsx: (
              <Link
                button={BUTTON_STYLE.SECONDARY}
                target="_blank"
                rel="noopener noreferrer"
                href={hardshipApplicationFormUrl}
                trackId="hardship--online--apply-for-hardship-care"
                trackEventData={{
                  location: "What's the best way to contact Hardship Care?",
                  action: 'internal-link'
                }}
                css={`
                  margin: 8px auto 5px;
                  max-width: 220px;
                `}
              >
                Apply for Hardship Care
              </Link>
            )
          },
          {
            icon: 'icon-call',
            title: 'Phone',
            jsx: (
              <Box css="text-align: center;">
                <span data-nosnippet>
                  Call our team on
                  <br />
                  <Tel no={hardshipPhoneNumber} />
                  <br />
                  Mon - Fri, 8:30am to 5:00pm (NZST)
                </span>
              </Box>
            )
          },
          {
            icon: 'icon-mail',
            title: 'Mail',
            text:
              '<strong>Gem Hardship Care</strong><br/>8 Tangihua Street<br/>Auckland 1010'
          }
        ]}
        heading="What's the best way to contact Hardship Care?"
        subheading="The best and most convenient way for you to contact us is online, using our Hardship Care application form. If that doesn't suit, get in touch with us via phone or mail."
      />
    </Box.Section>
  </AnalyticsLocationProvider>
);

const ApplyingForHardshipCareSection = () => (
  <Section
    id="applying-for-hardship-care"
    align={ALIGN.LEFT}
    css={`
      padding: 72px 0 !important;
      background-color: ${COLOR.GREY6};
    `}
  >
    <Box
      backgroundColor={COLOR.GREY6}
      css={`
        max-width: 730px;
        margin: 0 auto;
        padding: 0;
        text-align: left;
      `}
    >
      <Heading4
        align={ALIGN.CENTER}
        color={COLOR.BLACK}
        css={`
          margin: 0 0 24px;
        `}
      >
        Applying for Hardship Care
      </Heading4>
      <Text>
        Our Hardship Care team has helped many customers just like you who are
        having difficulty keeping up with their Latitude repayments, so don't be
        put off. We'll either come up with a manageable way for you to handle
        your repayments, or we'll direct you to external support services who
        may be able to assist.
        <br />
        <br />
        The first thing you need to do is fill out our{' '}
        <strong>Hardship Care online application.</strong>
      </Text>
      <Heading6
        color={COLOR.BLACK}
        css={`
          margin: 40px 0 16px;
        `}
      >
        What happens next?
      </Heading6>
      <List css="margin: 20px;">
        <ListItem>
          Once we&rsquo;ve received your application, we&rsquo;ll let you know
          if we need more information, otherwise we'll get back to you within 20
          business days with an outcome.
        </ListItem>
        <ListItem>
          We&rsquo;ll create a plan to get you back on track or we&rsquo;ll
          provide information about other options you can consider.
        </ListItem>
      </List>
      <AnalyticsLocationProvider location="Applying for Hardship Care">
        <div
          className="row justify-content-center"
          css={`
            padding-top: 30px;
          `}
        >
          <Link
            href={hardshipApplicationFormUrl}
            target="_blank"
            rel="noopener noreferrer"
            trackId="apply-for-hardship-care"
            button="secondary"
          >
            Apply for Hardship Care
          </Link>
        </div>
      </AnalyticsLocationProvider>
    </Box>
  </Section>
);

const InsuranceSection = () => (
  <Section
    id="insurance"
    align={ALIGN.LEFT}
    css={`
      padding: 72px 0 !important;
      background-color: ${COLOR.GREY6};
      .section__content > div > div {
        > div:last-child {
          max-width: 400px;
          padding-left: 32px;
          padding-right: 32px;
          margin-left: auto;
          margin-right: auto;
          > * {
            text-align: left;
          }
        }
      }
      @media (min-width: ${BREAKPOINT.LG}) {
        img {
          width: 308px;
          height: 273px;
        }
        .section__content > div > div {
          align-items: center;
          transform: translateX(-85px);
          > div:first-child {
            width: 308px;
            height: 273px;
          }
          > div:last-child {
            min-width: 520px;
            padding-top: 0;
          }
        }
      }
    `}
  >
    <Box
      css={`
        max-width: 730px;
        margin: 0 auto;
      `}
    >
      <Promo
        description={
          <>
            <Heading5 css="margin-bottom: 16px;" color={COLOR.BLACK}>
              Do you have Insurance with Latitude?
            </Heading5>
            <AnalyticsLocationProvider location="Do you have Insurance with Latitude?">
              <Text>
                You can check if you have insurance for your Gem credit card on
                your statement. Or for personal loans, check your loan contract.
                <br />
                <br />
                If you have consumer credit insurance with us, depending on your
                policy you may be able to claim if the insured is unable to work
                due to sickness, injury or job loss. It is a good idea to speak
                with the Hallmark Insurance team to see if you're able to make a
                claim.
                <br />
                <br />
                <strong>
                  You can still apply for Hardship Care while you make your
                  insurance claim if you wish.
                </strong>
                <br />
                <br />
              </Text>
              <List css="margin-bottom: 20px;">
                <ListItem>
                  Contact Hallmark Insurance on <Tel no="0800 220 999" />
                  {' or'}
                </ListItem>
                <ListItem>
                  email:{' '}
                  <Link
                    href="mailto:customerservice@hallmarkinsurance.com.au"
                    trackId="make-a-claim-email"
                  >
                    customerservice@hallmarkinsurance.com.au
                  </Link>
                  {' or'}
                </ListItem>
                <ListItem>
                  Check website:{` `}
                  <Link
                    href="https://www.hallmarkinsurance.co.nz/"
                    trackId="insurance-contact-web"
                    rel="noopener noreferrer"
                  >
                    www.hallmarkinsurance.co.nz
                  </Link>
                </ListItem>
              </List>
            </AnalyticsLocationProvider>
          </>
        }
        frameImage={<img src={require('./insurance-square.svg')} alt="" />}
        frameAlignment="left"
        frameBackgroundColor="#F2F2FD"
        frameBorderColor="#E5E4FB"
      />
    </Box>
  </Section>
);

const UsefulInformationSection = () => (
  <Box
    id="useful-info"
    css={`
      padding: 72px 0 !important;
      h5 {
        color: ${COLOR.BLACK};
        font-size: 20px;
      }
      h6 {
        font-size: 16px;
        margin: 16px 0 8px;
      }
      h6 + p {
        margin-top: 0;
      }
    `}
  >
    <ManageYourLoan
      heading={PageData.content.usefulInfo.title}
      description={
        <>
          We want you to know that you&rsquo;re not alone when going through
          financial hardship. We&rsquo;re always here to help and our customer{' '}
          <a
            href="https://gemfinance.zendesk.com/hc/en-nz"
            target="_blank"
            rel="noreferrer noopener"
          >
            Help Centre
          </a>{' '}
          has some articles on Hardship Care that may assist further.&nbsp;There
          are also a number of other resources you can turn to for support and
          guidance.
        </>
      }
    >
      <Accordion
        titleBgColor="grey-light"
        items={PageData.content.usefulInfo.accordionData.map(
          accordionDataItem => {
            return {
              id: accordionDataItem.id,
              title: accordionDataItem.title,
              content: (
                <Box padding={PADDING.P16}>
                  {ReactHtmlParser(accordionDataItem.data)}
                </Box>
              )
            };
          }
        )}
      />
    </ManageYourLoan>
  </Box>
);

export default Hardship;
